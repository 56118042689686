<!-- 扫码 -->
<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="130px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="供应商：" prop="supplierBodyName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.supplierBodyName }}</p>
                <el-input :disabled="true" v-else v-model="ruleForm.supplierBodyName" placeholder="请选择供应商">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品名称：" prop="productName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productName }}</p>
                <el-input :disabled="true" v-else v-model="ruleForm.productName" placeholder="请选择产品名称">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p class="tips-red pl20">合格证产品剩余收购量：{{ (ruleForm.residualWeight || 0)  + (ruleForm.weightUnit || '')}}</p>
          <el-row>
            <el-col :span="24">
              <el-form-item label="收购商品重量：" prop="practicaWeight">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.practicaWeight + ruleForm.weightUnit}}</p>
                <el-input v-else @input="practicaWeightChange" v-model="ruleForm.practicaWeight" placeholder="请输入收购商品重量" maxlength=14 show-word-limit class="input-with-select">
                  <template slot="append">{{ ruleForm.weightUnit }}</template>
                  <!-- <el-select v-model="ruleForm.weightUnit" slot="append" placeholder="">
                    <el-option
                      v-for="(item, index) in selectObj.weight"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> -->
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="收购价格：" prop="marketPrice">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.marketPrice }}</p>
                <el-input clearable v-else @input="marketPriceChange" v-model="ruleForm.marketPrice" placeholder="请输入收购价格" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="收购金额：" prop="practicalMarketPrice">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.practicalMarketPrice }}</p>
                <el-input clearable v-else @input="practicalMarketPriceChange" v-model="ruleForm.practicalMarketPrice" placeholder="请输入收购金额" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="我收到的承诺达标合格证：" prop="cscUrl" class="my-upload-files">
                <my-image  
                  v-if="ruleForm.cscUrl"
                  style="width: 100px; height: 100px"
                  :src="ruleForm.cscUrl" 
                  :preview-src-list="[ruleForm.cscUrl]">
                </my-image>
                <div v-else class="no-data">无图片</div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="showHide.animalCscUrl">
            <el-col :span="24">
              <el-form-item label="动物检疫合格证：" prop="animalCscUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.animalCscUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.animalCscUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.animalCscUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics('animalCscUrl')" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="showHide.quarantineUrl">
            <el-col :span="24">
              <el-form-item label="肉品品质检验合格证：" prop="quarantineUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.quarantineUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.quarantineUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.quarantineUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics('quarantineUrl')" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="收货单收购凭证：" prop="voucherUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.voucherUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.voucherUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.voucherUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics('voucherUrl')" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <!-- <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitAddNew()">保存后复制新增</el-button> -->
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>

    <!-- 动物检疫合格证 -->
    <pics ref="animalCscUrl" dictCode="104004" :businessCode="ruleForm.transactionRecordId" :imgSize="0.5" propName="animalCscUrl" storeTableName="examiningReportFileDirectoryTable" @getpics="getpics"></pics>

    <!-- 肉品品质检验合格证 -->
    <pics ref="quarantineUrl" dictCode="104005" :businessCode="ruleForm.transactionRecordId" :imgSize="0.5" propName="quarantineUrl" storeTableName="examiningReportFileDirectoryTable" @getpics="getpics"></pics>

    <!-- 收货单收购凭证 -->
    <pics ref="voucherUrl" dictCode="105001" :businessCode="ruleForm.transactionRecordId" :imgSize="0.5" propName="voucherUrl" storeTableName="purchaseDocumentDirectoryTable" @getpics="getpics"></pics>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import pics from '@/views/common/pics.vue'
let _t = null
export default {
  name: "buyEdit2",
  components: {
    pics
  },
  props:{
  },
  data() {
    return {   
      rules: {
        productionAddress: [{ required: true,  trigger: "change", message: '请选择产地'}],
        practicaWeight: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入商品重量"))
            }else if(!baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              // let price = _t.ruleForm.marketPrice
              // if(price){
              //   _t.ruleForm.practicalMarketPrice = price * val
              //   this.$forceUpdate()
              // }
              callback()
            }
          }
        }],
        marketPrice: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              // let resWeight = _t.ruleForm.practicaWeight
              // if(val && resWeight){
              //   _t.ruleForm.practicalMarketPrice = resWeight * val
              //   this.$forceUpdate()
              // }
              callback()
            }
          }
        }],
        practicalMarketPrice: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              // let resWeight = _t.ruleForm.practicaWeight
              // if(val && resWeight){
              //   _t.ruleForm.marketPrice = val / resWeight
              //   this.$forceUpdate()
              // }
              callback()
            }
          }
        }]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      initForm: {
        transactionRecordId: '',
        animalCscUrl: [],
        quarantineUrl: [],
        voucherUrl: [],
        supplierBodyName: '',
        supplierBodyId: '',
        consigneeId: utils.getBodyId(),
        consigneeName: utils.getBodyName(),
        bodyId: utils.getBodyId(),
        examiningReportFileDirectoryTable: '',
        purchaseDocumentDirectoryTable: '',
        productName: '',
        residualWeight: 0,
        weightUnit: '千克',
        marketPrice: '',
        practicalMarketPrice: '',
        practicaWeight: ''
      },
      selectObj: {
        baseNames: [],
        weight: CONSTPARAMS.saleUnit
      },
      historyName: '历史名称',
      showHide: {
        animalCscUrl: false,//动物检疫合格证
        quarantineUrl: false, //肉品品质检验合格证
      },
    };
  },
  created() {
    this.setshowHide()
    this.getBaseName()
	},
  mounted() {
    _t = this
  },
  methods: {
    practicaWeightChange(val){
      if(isNaN(val)) return
      let price = this.ruleForm.marketPrice
      this.ruleForm.practicalMarketPrice = utils.getFixedNumber(price * val)
      this.$forceUpdate()
    },
    marketPriceChange(val){
      if(isNaN(val)) return
      let resWeight = this.ruleForm.practicaWeight
      this.ruleForm.practicalMarketPrice = utils.getFixedNumber(resWeight * val)
      this.$forceUpdate()
    },
    practicalMarketPriceChange(val){
      if(isNaN(val)) return
      let resWeight = this.ruleForm.practicaWeight
      if(resWeight){
        this.ruleForm.marketPrice = utils.getFixedNumber(val / resWeight)
      }
      this.$forceUpdate()
    },
    getBaseName(){
      let _this = this
      request.psccgetHistoryBaseName(utils.getBodyId()).then(res => {
        if(res.code == 200){
          _this.selectObj.baseNames = res.data || []
          // _this.ruleForm.baseName = res.data && res.data[0] || ''
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    historyChange(data){
      this.historyName = '历史名称'
      this.ruleForm.baseName = data
    },
    setshowHide(){
      let bodyType = utils.getBodyType()
      if(bodyType == '07'){
        this.showHide.animalCscUrl =  true
        this.showHide.quarantineUrl =  true
      }else{
        this.showHide.animalCscUrl =  false
        this.showHide.quarantineUrl =  false
      }
    },
    chooseType(name){
      this.$refs[name].initData()
    },
    addPics(name){
      this.$refs[name].initData(this.ruleForm[name])
    },
    getpics(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    async initData(title, editType, ruleForm, transobj = {}){
      if(editType == 'add'){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          ...transobj,
          transactionRecordId: id,
        }
        this.ruleForm = obj
      }else{
        this.ruleForm = ruleForm
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    submitAddNew(){
      this.submitForm(true)
    },
    async submitForm(flag = false) {
      let _this = this,
        editType = this.editType,
        formName = 'ruleForm',
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        },
        newId = flag ? await utils.getNewId() : ''
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'commonsavepurchaseorder' : 'commonsavepurchaseorder',
            target = {
            ..._this.ruleForm,
            animalCscUrl: _this.ruleForm.animalCscUrl?.join(',') || '',
            quarantineUrl: _this.ruleForm.quarantineUrl?.join(',') || '',
            voucherUrl: _this.ruleForm.voucherUrl?.join(',') || '',
            // cscUrl: _this.ruleForm.cscUrl?.join(',') || '',
            practicalMarketPrice: parseFloat(_this.ruleForm.practicalMarketPrice || 0),
            practicaWeight: parseFloat(_this.ruleForm.practicaWeight || 0),
            marketPrice: parseFloat(_this.ruleForm.marketPrice || 0)
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              if(flag){
                _this.ruleForm.transactionRecordId = newId
              }else{
                _this.closeModel()
                _this.$emit("getData", editType == 'add' ? true : false)
              }
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>


