<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="800px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
  >
    <div>
      <p>所有产品总重量：{{ listData.allWeight || 0}}</p>
      <el-table 
        ref="table" 
        class="my-table min-dialog-height"
        size="small" 
        :data="listData.returnDateList" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        height="calc(100vh - 50px)"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" :loading="loading" size="small" @click="sure()">确认</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> 取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'codeList',
    components: {
    },
    props:{
    },
    data() {
      let title = '扫码查验合格证采购'
      return {
        closeFlag: false,
        loading: false,
        title: title,
        tableHead: [{
          prop: 'productName',
          label: '产品名称'
        },{
          prop: 'itemCode',
          label: '合格证一物一码'
        },{
          prop: 'practicaWeight',
          label: '重量',
          width: 180
        }],
        loadtext: '加载中',
        pageName: title,
        loading: false,
        tableHeight: null,
        listData: {}
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
      sure(){
        let url = 'commonsavebatch',
          _this = this
        request[url]({
          ..._this.listData,
          consigneeId: utils.getBodyId(),
          consigneeName: utils.getBodyName(),
          bodyId: utils.getBodyId(),
        }).then(res => {
          if(res.code == 200){
            _this.message('新增成功', "success")
            _this.closeModel()
          }else {
            _this.message(res.msg, "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      initData(data){
        this.listData = data
        this.closeFlag = true
        this.$nextTick(() => {
          this.$refs.table &&  this.$refs.table.doLayout()
        })
      },
      closeModel() {
        this.closeFlag = false
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>