<template>
  <div class="page-contain my-table-list">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line">
            <el-form-item label="产品名称:">
              <el-input size="small" v-model="searchForm.productName" placeholder="请输入产品名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="供应商:">
              <el-input size="small" v-model="searchForm.supplierName" placeholder="请输入供应商" clearable></el-input>
            </el-form-item>
            <el-form-item label="收购日期:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange"
                @change="timeChange"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="pt5">
        <div class="title-tab-btns">
          <!-- <el-upload
            class="upload-btn"
            list-type="text"
            ref="uploadFile"
            action
            accept="image/*"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="scanData"
            :limit="1"
            >
            <el-button size="small" icon="el-icon-plus" type="primary">扫码查验合格证采购</el-button>
          </el-upload> -->
          <el-button type="primary" size="small" icon="el-icon-plus" @click="sanInput">扫码查验合格证采购</el-button>
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">手动新增采购</el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="download">导出</el-button>
          <!-- <el-input v-model="tempscanData" ref="scanInput" type="text"/> -->
        </div>
        <p class="color-red pl0">扫码查验合格证时，请购买XXXX扫码枪，如果还没购买时，请进微信小程序“农产品质量安全承诺达标合格证”--“我要采购”中使用，无须购买扫码枪！</p>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="我收到的合格证" width='150px' prop="cscUrl">
          <template slot-scope="scope">
            <my-image 
              v-if="scope.row.cscUrl"
              style="height: 100px"
              :src="scope.row.cscUrl" 
              :preview-src-list="[scope.row.cscUrl]">
            </my-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" v-if="scope.row.realStatus == '01'" type="text" @click="cancelOrder(scope.row, scope.$index)">取消采购</el-button>
            <el-button size="small" v-if="scope.row.realStatus == '02'" type="text" @click="comfirmOrder(scope.row, scope.$index)">确认采购</el-button>
            <el-button size="small" v-if="scope.row.realStatus == '03'" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <!-- 手动 -->
    <buy-edit ref="editForm" @getData="getData"></buy-edit>
    <buy-edit2 ref="editForm2" @getData="getData"></buy-edit2>
    <code-list ref="codeList"></code-list>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import codeList from '../common/codeList.vue'
  import buyEdit from './buyEdit.vue'
  import buyEdit2 from './buyEdit2.vue'
  import * as XLSX from "xlsx"
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'buyList',
    components: {
      pagination,
      breadcrumb,
      buyEdit,
      buyEdit2,
      codeList
    },
    data() {
      let title = '我要采购'
      return {
        timeRange: '',
        loadtext: '加载中',
        breadStaticData:['承诺达标合格证管理', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          consigneeId: utils.getBodyId(),
          productName: '',
          supplierName: '',
          startTime: '',
          endTime: ''
        },
        urlName: 'psfindpurchaseorder',
        tableHead: [{
          prop: 'supplierName',
          label: '上游供应商'
        },{
          prop: 'productName',
          label: '产品名称'
        },{
          prop: 'createTime',
          label: '采购时间',
          width: 150
        },{
          prop: 'practicaWeight',
          label: '采购重量',
          width: 120
        },{
          prop: 'marketPrice',
          label: '单价',
          width: 100
        },{
          prop: 'practicalMarketPrice',
          label: '金额',
          width: 100
        },{
          prop: 'producer',
          label: '生产者'
        },{
          prop: 'productionAddress',
          label: '产地'
        }],
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        },
        tempscanData: '',
        isScan: false
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
      this.scan()
    },
    methods: {
      sanInput(){
        this.isScan = true
        this.message('开启成功，请使用扫码枪!', "warning")
      },
      scan() {
        let barCode = '',
          lastTime = 0,
          _this = this

        function ClearBarCode() {
          barCode = ''
          lastTime = 0
        }

        window.addEventListener('keypress', function (e) {
          e = e || window.event
          let currCode = e.keyCode || e.which || e.charCode,
            currTime = new Date().getTime()
          if (lastTime > 0) {
            if (currTime - lastTime <= 80) {
              barCode += String.fromCharCode(currCode)
            } else if (currTime - lastTime > 80) {
              ClearBarCode()
            }
          } else {
            barCode = String.fromCharCode(currCode)
          }
          lastTime = currTime

          if (currCode == 13) {
            if (barCode && barCode.length >= 16) { 
              console.log("扫码结果：" + barCode + '，长度：' + barCode.length)
              _this.isScan && _this.dealData(barCode)
              
            }
            ClearBarCode()
          }
        })
      },
      async dealScanData(data){
        let code = await utils.getQrCode(data)
        if(code){
          this.dealData(code)
        }else{
          this.message("扫描失败！", "error")
        }
      },
      transData(data){
        //01采购方，02供货方
        //recorder 02 supplierStatus
        //recorder 01 consigneeStatus
        let obj = {
          '01': '已确定', 
          '02': '待确定', 
          '03': '取消'
        },
        status = ''
        if(data.recorder == '01'){
          status = data.consigneeStatus
        }else{
          status = data.supplierStatus
        }
        return obj[status] || ''
      },
      getStatus(data){
        //01采购方，02供货方
        //recorder 02 supplierStatus
        //recorder 01 consigneeStatus
        let status = ''
        if(data.recorder == '01'){
          status = data.consigneeStatus
        }else{
          status = data.supplierStatus
        }
        return status
      },
      download(){
        let html = '<table><tr><th>序号</th><th>上游供应商</th><th>产品名称</th><th width="130px">采购时间</th><th>采购重量</th><th>单价</th><th>金额</th><th>生产者</th><th>产地</th><th>我发出去的承诺达标合格证</th></tr>',
          data = this.listData
        if(!data || !data.length){
          this.message('暂无数据，无法导出！', 'warning')
          return
        }
        for(let i = 0, len = data.length; i < len;i++){
          let item = data[i]
          html += '<tr>'
          html += `<td>${i + 1}</td>`
          html += `<td>${item.supplierName}</td>`
          html += `<td>${item.productName}</td>`
          html += `<td>${item.createTime}</td>`
          html += `<td>${item.practicaWeight}</td>`
          html += `<td>${item.expectPrice}</td>`
          html += `<td>${item.practicalMarketPrice}</td>`
          html += `<td>${item.producer}</td>`
          html += `<td>${item.productionAddress}</td>`
          html += `<td><img style="height: 50px;width: 50px;" src="${item.cscUrl}" class="el-image__inner"></td>`
          html += '</tr>'
        }
        html += '</table>'
        utils.tableToNotIE(html, this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx')
        return
        let arr = this.listData,
        tableData = [['序号', '上游供应商', '产品名称', '采购时间', '采购重量', '单价', '金额',  '生产者', '产地',  '我收到的合格证']] // 表格表头
        if(!arr || !arr.length){
          this.message('暂无数据，无法导出！', 'warning')
          return
        }
        arr.forEach((item, index) => {
          let rowData = []
          rowData = [
            index + 1,
            item.supplierName,
            item.productName,
            item.createTime,
            item.practicaWeight,
            item.expectPrice,
            item.practicalMarketPrice,
            item.producer,
            item.productionAddress,
            item.cscUrl
          ]
          tableData.push(rowData)
        })
        this.loading = true
        this.loadtext = '导出中'
        let workSheet = XLSX.utils.aoa_to_sheet(tableData);
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, '数据') // 工作簿名称
        let name = this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx'
        XLSX.writeFile(bookNew, name) 
        this.loadtext = '加载中'
        this.loading = false
      },
      timeChange(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0])
          endTime = utils.dateFormat(val[1])
        }
        this.searchForm.startTime = startTime
        this.searchForm.endTime = endTime
      },
      async scanData(){
        let _this = this
        if (this.$refs.uploadFile.uploadFiles.length > 0) {
          let file = this.$refs.uploadFile.uploadFiles[0]
          let code = await utils.getQrCode(file.raw)
          _this.$refs.uploadFile.clearFiles()
          if(code){
            this.dealData(code)
          }else{
            this.message("扫描失败！", "error")
          }
        } else {
          this.message("请选择文件后再导入", "warning")
        }
      },
      dealData(resScanD){
        let _this = this
        let obj = utils.getParamCode('cd', resScanD)
        if(!obj) return
        if(!obj.procurementCode){
          _this.message('获取数据失败！', "error")
          return
        }
        this.requestData(obj)
      },
      requestData(obj){
        let _this = this
        request.psfindcertificate({
          procurementCode: obj.procurementCode,
          startCode: obj.startCode,
          endCode: obj.endCode,
          bodyId: utils.getBodyId()
        }).then(res => {
          if(res.code == 200){
            if(res.data.subCode){
              let tempData = {
                ...res.data.purchase
              }
              _this.$refs.editForm2.initData(`新增${_this.pageName}`, 'add', tempData)
              _this.isScan = false
            }else{
              _this.$refs.codeList.initData(res.data || {})
            }
          }else{
            _this.message(res.msg || '', "error")
          }
          // this.isScan = false
        }).catch(e => {
          console.log(e)
        })
      },
      async initTable(){
        this.getData()
      },
      async add(){
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add')
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.purchasefind({
          transactionRecordId: data.transactionRecordId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              voucherUrl: result.voucherUrl ? result.voucherUrl.split(',') : [],
              quarantineUrl: result.quarantineUrl ? result.quarantineUrl.split(',') : [],
              animalCscUrl: result.animalCscUrl ? result.animalCscUrl.split(',') : [],
              cscUrl: result.cscUrl ? result.cscUrl.split(',') : [],
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      cancelOrder(data, index){
        let _this = this
        _this.$confirm(`确认要取消采购${data.supplierName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.commonpspurchaseupdatestatus({
            consigneeStatus: '03',
            transactionRecordId: data.transactionRecordId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('取消采购成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      comfirmOrder(data, index){
        let _this = this
        _this.$confirm(`确认要确认采购${data.supplierName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.commonpspurchaseupdatestatus({
            consigneeStatus: '01',
            transactionRecordId: data.transactionRecordId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('确认采购成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      del(data, index){
        let _this = this
        _this.$confirm(`确认要删除${data.supplierName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.pspurchaseselldeleteorder({
            consigneeId: utils.getBodyId(),
            transactionRecordId: data.transactionRecordId || ''
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight() + (document.querySelector('.page-table .pt5')?.clientHeight || 0) 
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                supplierStatusName: _this.transData(item),
                realStatus: _this.getStatus(item),
                practicaWeight: (item.practicaWeight || '') + (item.weightUnit || '')
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
.my-input-btn ::v-deep .el-input__inner{
  height: 32px;
  line-height: 32px;
}
.title-tab-btns{
  display: flex;
  align-items: center;
}
.upload-btn{
  padding-right: 10px;
}
</style>